@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap");

.rpc-logo-div {
  position: absolute;
  height:70px;
  width:70px;
  left:10px;
  top:10px;

  background-image: url('rpclogo.png');
  background-size:contain;

  cursor:pointer;

  z-index:10000;
}

.top-header-div {
  position:fixed;

  width:100%;
  height:85px;

  background-color:white;
  box-shadow: 0px 7px 15px -6px #000000;

  z-index:100;
}

.top-header-button-container {
  position:fixed;
  right:0px;
  top: 7.5px;

  width:700px;
  height:70px;

}

.top-header-button {
  display:inline-block;
  vertical-align: top;
  margin-right:10px;
  right:20px;

  width:120px;
  height:70px;

  border:1px solid white;

  text-align:center;

  cursor:pointer;

  transition: background-color .1s, border .1s;
}

.top-header-button:hover {
  border:1px solid rgba(0, 0, 0, 1);
}

.top-header-button:active {
  background-color:rgb(0, 0, 133);
  color:white;
}

.top-header-button h3 {
  position:relative;
  top:10px;

  font-size:15px;
  font-family:"Montserrat";
}

@media only screen and (max-width:480px) {

  /* .top-header-div {
    position:fixed;
  
    width:100%;
    height:85px;
  
    background-color:white;
    box-shadow: 0px 7px 15px -6px #000000;
  
    z-index:100;
  } */

  .top-header-button-container {
    position:relative;
    left:90px;
    width:300px;

    text-align:center;
  }

  .top-header-button {
    width:70px;
    height:40px;

    border: 0px solid white;
  }

  .top-header-button h3 {
    position:relative;
    top:-7px;
  
    font-size:15px;
    font-family:"Lexend";
    font-weight:300;
  }

  .top-header-button:hover {
    border:0px solid rgba(0, 0, 0, 1);
  }
  
  .top-header-button:active {
    background-color:white;
    color:black;
  }
}