.events-page-event {
    position: relative;
    display: inline-block;
    vertical-align: top;

    margin-bottom: 10px;
    margin-left: 5px;
    margin-right: 5px;
    width: 400px;
    height: 150px;

    border: 1px solid rgb(75,75,75);
    padding-left: 10px;
    padding-right: 10px;
    text-align:left;

    font-family: "Lexend";

    overflow:scroll;
}

.events-page-event p {
    position:relative;
    color: rgb(75,75,75);
    font-size:15px;
}

.events-page-event h1 {
    position: relative;
    margin-top: -10px;
    font-weight:400;
    color:black;
    font-family:"Lexend";

    font-size:20px;
}

.events-page-event h4 {
    position:relative;
    top:-15px;
    font-weight:250;
}

@media only screen and (max-width:480px) {
    .events-page-event {
        width: 300px;
        height: 150px;
    }
}