@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap");

#starting-page-div {
    position:relative;
    top:110px;
    width:80%;
    left:10%;
    margin-bottom:100px;
}

#starting-page-div h1 {
    color:rgb(0, 0, 0);
    font-family: "Lexend";
    font-weight:400
}

#starting-page-div h4 {
    position: relative;
    margin-top:-10px;
    color:rgb(75,75,75);
    font-family: "Lexend";
    font-weight:400
}

#starting-page-div p {
    color:rgb(75, 75, 75);
}

#starting-page-div h2 {
    color:rgb(75, 75, 75);
    font-size:33px;
}

#logo-section {
    text-align: center;
    margin-bottom: 50px;
}

.logo-box {
    position: relative;
    vertical-align: top;
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 10px;
    width: 300px;
    height: 200px;
    border: 1px solid rgb(75,75,75);
}

.logo-box-top {
    position:relative;
    height: 70%;

    text-align: center;
}

.logo-box-bottom {
    text-align: left;
}

.logo-box-bottom p {
    position: relative;
    padding-left: 10px;
    margin-top: 5px;
    color:rgb(75,75,75);
    font-family: "Lexend";
}

.logo-box-bottom h3 {
    position: relative;
    padding-left: 10px;
    margin-top: -15px;
    color:rgb(0, 0, 0);
    font-family: "Lexend";
}

.starting-page-logo {
    display:inline-block;
    margin:10px;

    width:400px;
    height:200px;
}

#verizon-logo {
    position: relative;
    background-image: url("./Image.jpeg");
    height: 150px;
    left: 30px;
    background-size: contain;
    background-repeat:no-repeat;
}

#other-logo {
    position:relative;
    top:40px;
    left:-10px;
    width: 300px;
    background-size: contain;
    background-image: url("./aasocv.jpg");
    background-repeat:no-repeat;
}

#faacv-logo {
    position:relative;
    left: 70px;
    height: 150px;

    background-size: contain;
    background-image: url("./faacv.jpg");
    background-repeat:no-repeat;
}

#about-us-section {
    text-align: center;
 
}

.about-us-half {
    position:relative;
    display: inline-block;
    vertical-align: top;
    width: 500px;
    height: 300px;

}

#about-us-left {
    text-align: left;
}

#about-us-left h1 {
    position: relative;
    top: 50px;
    text-align: left;
    font-family: "Lexend";
}

#about-us-left p {
    position: relative;
    top: 50px;
    text-align: left;
    font-family: "Lexend";
}

#about-us-right {
}



#rpc-class-image {
    margin:0 auto;
    max-width:400px;
    height:300px;
    background-image: url("./classpic1.JPG");
    background-size: contain;

    box-shadow: 5px 5px 15px -10px #000000;
}

#starting-page-div iframe {
    margin:30px;
    width:400px;
    height: 300px;
}

#about-us-h1 {
    font-size:40px;
    color:black;
}

@media only screen and (max-width:480px) {
    #rpc-class-image {
        margin:0 auto;
        width:300px;
        height:200px;
        background-image: url("./classpic1.JPG");
        background-position:center;
        overflow: hidden;  
    
        box-shadow: 5px 5px 15px -10px #000000;
    }

    #about-us-section {
        position: relative;
        text-align: center;
        top: -70px;
    }

    #about-us-left {
        text-align: center;
        max-width: 300px;
    }
    
    #about-us-left h1 {
        position: relative;
        top: 50px;
        text-align: left;
        font-family: "Lexend";
    }
    
    #about-us-left p {
        position: relative;
        top: 50px;
        text-align: left;
        font-family: "Lexend";
    }
    
    #about-us-right {
        display:none;
    }



    #faacv-logo {
        left: 65px;
    }

    #rpc-class-image {
        display: none;
    }
    
    #starting-page-div iframe {
        display: none;
    }
}