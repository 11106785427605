#features-page-div {
    position:relative;
    top:85px;
    text-align:center;
}

#features-image-container {
    position:relative;
    top:85px;
    text-align:center;
}

.photo-div {
    position:relative;
    display: inline-block;
}