@import url("https://fonts.googleapis.com/css2?family=Arvo:wght@700&display=swap");

#global-header-div {
    position:relative;
    top:85px;

    width:100%;
    height:600px;
    
    background-color:red;
    background: linear-gradient(45deg, rgba(26, 22, 245, 0.5), rgba(134, 0, 0, 0.5) ), url('globalheaderimage.jpg');

    text-align:center;
}

#global-header-text-div {
    /* The next six lines are to vertically and horizontally center*/
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    color:white;

    font-family: "Arvo"
}

#main-big-text {
    font-size: 90px;
    text-shadow: 2px 2px #000000;
}

#small-below-text {
    position:relative;
    top:-45px;
}

@media only screen and (max-width:480px) {

    #main-big-text {
        font-size: 60px;
        text-shadow: 2px 2px #000000;
    }
    
    #small-below-text {
        position:relative;
        font-size:15px;
        top:-45px;
    }
}