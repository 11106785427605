@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap");

#photos-page-div {
    position:relative;
    text-align:center;
    top:100px;
    /* width:80%;
    left:10%; */

    font-family: "Montserrat";
}

.photos-selector-button {
    position:relative;
    display:inline-block;
    vertical-align: top;
    margin:10px;

    width:300px;
    height:50px;

    border:1px solid gray;

    cursor:pointer;
    text-align: center;

    transition: background-color .3s, color .3s;
}

.photos-selector-button:hover {
    background-color:rgb(0, 0, 133);
    color:white;
}

.photos-selector-button h4 {
    position:relative;
    top: -5px;
}

iframe {
    height: 300px;
    width:400px;
}

.photos-button-for-mobile {
    display: none;


}

.image-container {
    position:relative;
    top:85px;
    text-align:center
}

@media only screen and (max-width:480px) {
    .photos-selector-button {
        width:280px;
    }

    .photos-button-for-mobile {
        display: block;
        margin: 0 auto;
        width: 200px;
        height: 50px;

        border: 1px solid black;
        
        cursor: pointer;
    }

    .photos-button-for-mobile h4 {
        position: relative;
        top: -5px
    }
}