@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap");


.decorative-div {
    position:relative;

    width:50%;
    left:25%;

    border-bottom: 1px solid gray
}

#videos-div-main {
    position:relative;
    top: 80px;

    margin:0 auto;
    width:100%;

    text-align:center;
}

#videos-div-main h1 {
    font-family:'Lexend'
}

.videos-subdiv {
    padding:10px;
}

/* .videos-page-photo {
    position:relative;
    display:inline-block;

    margin:10px;
    max-width:400px;
    max-height:200px;

    box-shadow: 5px 5px 15px -10px #000000;
} */

.video-div {
    position:relative;
    display:inline-block;
    vertical-align:top;

    margin:10px;

    height:110px;
    width:300px;

    border: 1px solid rgb(75,75,75);
    cursor:pointer;

    text-align: left;

    overflow:scroll;
}

.video-div p {
    position: relative;
    padding-left: 10px;
    color:rgb(75,75,75);
    font-family:"Lexend";

    font-size:15px;
}

.video-div-date {
    position: relative;
    margin-top: -18px;
    padding-left: 10px;

    color:rgb(75,75,75);
    font-family:"Lexend";
    font-weight: 500;
    font-size: small;
}

.video-div h1 {
    position: relative;
    margin-top: -28px;
    font-weight:400;
    padding-left: 10px;
    padding-right: 10px;
    color:black;
    font-family:"Lexend";

    font-size:20px;
}



@media only screen and (max-width:480px) {
    .decorative-div {
        position:relative;
    
        width:50%;
        left:25%;
    
        border-bottom: 1px solid gray
    }
    
    #videos-div-main {
        position:relative;
    
        margin:0 auto;
        width:100%;
    
        text-align:center;
    }
    
    #videos-div-main h1 {
        font-family:'Lexend';
    }
    
    .videos-subdiv {
        padding:10px;
    }
    
    .videos-page-photo {
        position:relative;
        display:inline-block;
    
        margin:10px;
        max-width:400px;
        max-height:400px;
    
        box-shadow: 5px 5px 15px -10px #000000;
    }
    
}