@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap");

#rpc-volunteers-title {
    position:relative;

    top:70px;
}

#contacts-page-div {
    position:relative;

    top: 110px;

    font-family:"Montserrat";
    text-align:center;
}

#contacts-page-div h5 {
    position:relative;
    top:-46px;
    font-weight:bolder;
    font-size:17px;
}

#general-email-div {
    position:relative;
    margin-bottom:50px;
}

#general-email-button {

    margin: 0 auto;

    width:300px;
    height:50px;
    border: 1px solid black;

    cursor:pointer;

    transition: background-color .3s, border .3s, box-shadow .3s, color .3s;
}

#general-email-button:hover {
    background-color:rgb(98, 181, 230);
    box-shadow: 5px 5px 15px -10px #000000;
    border:1px solid rgb(98, 181, 230);
    color:white;
}

#general-email-button h4 {
    position:relative;
    top: -6px;
    font-weight:bold;
}

#cielo-div {
    position:relative;
    margin:0 auto;

    width:90%;

    text-align:center;
}

.cielo-inner-div {
    position:relative;
    display:inline-block;
    vertical-align:top;
    top:20px;

    width:170px;
    height:170px;

    margin-left:10px;
    margin-right:10px;


    font-family:"Montserrat";
}

#cielo-picture-div {
    background-image: url('./contactphotos/cielo.JPG');
    background-size:contain;
 
}

#cielo-info-div {
    position:relative;
    top:-60px;
    width:400px;
    text-align:left;
}

#cielo-info-div h1 {
    position:relative;
    top:100px;
    left:40px;
    font-size:25px;
}

#cielo-info-div h5 {
    position:relative;
    top:80px;
    left:40px;
    
    font-weight:bold;
    color:rgb(85, 85, 85);
}

#decorative-line {
    position:relative;
    width:100px;
    top:70px;
    left:40px;

    border-bottom: 1px solid gray;
}

#colina-picture-div {
    background-image: url("./contactphotos/colina.jpg")
}

#igharas-picture-div {
    background-image: url('./contactphotos/igharas.PNG');
    background-size:100px;
}

#cielo-info-div h4 {
    position:relative;
    top:-15px;
    
}

#email-button {
    position:relative;
    top:90px;
    left:40px;

    padding:10px;
    width:220px;
    height:35px;

    text-align:center;

    box-shadow: .3s;
    border: 1px solid black;

    cursor:pointer;

    transition: background-color .3s, border .3s, box-shadow .3s, color .3s;
}

#email-button h4 {
    position:relative;

    top:-12px;
    font-weight:bold;
}

#email-button:hover {
    background-color:rgb(98, 181, 230);
    box-shadow: 5px 5px 15px -10px #000000;
    border:1px solid rgb(98, 181, 230);
    color:white;
}


#other-contacts-div {
    position:relative;
    top:50px;
    width:90%;
    margin:0 auto;

    text-align:center;

}

.contacts-div {
    position:relative;
    display:inline-block;

    margin:10px;
    margin-bottom:40px;
    width:400px;
    height:100px;

    font-family:"Montserrat";
    text-align:left;
}

.contacts-div-left {
    position:absolute;
    width:100px;
    height:100px;

    background-position:center;
    background-size:85px;

    text-align:center;

    border-radius:1000px;
    box-shadow: 5px 5px 15px -10px #000000;
}

.contacts-div-left h1 {
    position:relative;

    top:-8px;

    color:white;
    font-family:'Montserrat';
    font-size:40px;
}

.contacts-div-right {
    position:absolute;
    top:20px;
    left:100px;
    width:300px;
    height:100px;

}

.contacts-div-right h2 {
    position:relative;
    top:-15px;
    left:10px;
    
}

.contacts-div-right h5 {
    position:relative;
    top:-37px;
    left:12px;

    font-weight:100;
    color:rgb(83, 83, 83)
}

.other-email-button {
    position: absolute;
    top:55px;
    left:11px;

    width:250px;
    height:45px;

    border: 1px solid black;

    text-align:center;

    transition: background-color .3s, border .3s, box-shadow .3s, color .3s;

    cursor:pointer;
}

.other-email-button h4 {
    position:relative;
    top:-6px;

    font-size:15px;

}

.other-email-button:hover {
    background-color:rgb(98, 181, 230);
    box-shadow: 5px 5px 15px -10px #000000;
    border:1px solid rgb(98, 181, 230);
    color:white;
}

#other-volunteers-div {
    position:relative;

    top:50px;
    width:90%;
    margin:0 auto;

    text-align:center;
}

.other-volunteers-subdiv {
    position:relative;
    display:inline-block;
    vertical-align:top;

    margin:10px;

    width:300px;
    text-align:center;
}

.other-volunteers-subdiv h3 {
    position:relative;
    left:0px;

    font-family:"Arial";
}

.other-volunteers-subdiv h4 {
    position:relative;

    top:-15px;
    line-height:4px;
    font-family:"Arial";
    color:rgb(31, 31, 31);
    font-weight:100;
}

.other-volunteers-decorative-line {
    position:relative;
    top:-15px;
    width:30%;
    left:35%;
    border-bottom: 1px solid gray;
}

#volunteer-teachers-div {
    position:relative;
    top:60px;
    font-family:"Arial";
}

#volunteer-teachers-div h4 {
    font-weight:100;
    line-height: 2px;
}


@media only screen and (max-width:480px) {

    #general-email-div h1 {
        font-size:28px;
    }

    #cielo-div {
        display:none;
    }

    
    #decorative-line {
        position:relative;
        width:100px;
        top:-25px;
        left:40px;
    
        border-bottom: 1px solid gray;
    }
    
    #cielo-info-div h4 {
        display:hidden;
    }
    
    #email-button {
        position:relative;
        top:0px;
        left:40px;
    
        padding:10px;
        width:220px;
        height:35px;
    
        text-align:center;
    
        box-shadow: .3s;
        border: 1px solid black;
    
    
        cursor:pointer;
    
        transition: background-color .3s, border .3s, box-shadow .3s, color .3s;
    }
    
    #email-button h4 {
        position:relative;
    
        top:-12px;
        font-weight:100
    }
    
    #email-button:hover {
        background-color:rgb(98, 181, 230);
        box-shadow: 5px 5px 15px -10px #000000;
        border:1px solid rgb(98, 181, 230);
        color:white;
    }
    
    
    #other-contacts-div {
        display:none;
    
    }
    
    #other-volunteers-div {
        position:relative;
    
        top:50px;
        width:90%;
        /* height:100px; */
        margin:0 auto;
    
        text-align:center;
    }
    
    .other-volunteers-subdiv {
        position:relative;
        display:inline-block;
        vertical-align:top;
    
        margin:10px;
    
        width:300px;
        text-align:center;
    
    }
    
    .other-volunteers-subdiv h3 {
        position:relative;
        left:0px;
    
        font-family:"Arial";
    }
    
    .other-volunteers-subdiv h4 {
        position:relative;
    
        top:-15px;
        line-height:4px;
        font-family:"Arial";
        color:rgb(31, 31, 31);
        font-weight:100;
    }
    
    .other-volunteers-decorative-line {
        position:relative;
        top:-15px;
        width:30%;
        left:35%;
        border-bottom: 1px solid gray;
    }
}