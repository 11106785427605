@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap");

#events-page-section {
    position:relative;
    top:130px;
    text-align: center;
}

.event-div-separator-line {
    position:relative;
    top:10px;
    width:50%;
    left:25%;

    border-bottom:1px solid gray;
}

.event-div {
    margin: 0 auto;
    width:80%;
    text-align:center;

    font-family:"Montserrat"
}

.event-div h1 {
    font-size:30px;
}

@media only screen and (max-width:480px) {

    .event-div-separator-line {
        position:relative;
        top:10px;
        width:50%;
        left:25%;
    
        border-bottom:1px solid gray;
    }

    .event-div h1 {
        font-size:30px;
    }
}